
/*  <<  root element for the scrollable.
  when scrolling occurs this element stays still.
    >>
  */
 a.right:hover { background-position:-33px -33px;}
a.right:active { background-position:-33px -33px;}


/* left */
#Headlines a.left{ margin-left:0;}
a.left:hover  { background-position:-33px 0; }
a.left:active { background-position:-33px 0; }

/* up and down */
a.up, a.down  {
    background:url(vert_large.png) no-repeat;
    float: none;
    margin: 10px 50px;
}

/* up */
a.up:hover { background-position:-33px 0; }
a.up:active { background-position:-66px 0; }

/* down */
a.down { background-position: 0 -33px; }
a.down:hover { background-position:-33px -33px; }
a.down:active { background-position:-66px -33px; }


/* disabled navigational button */
a.disabled {
   
}

.navi a {
width: 10px;
height: 10px;
float: left;
margin: 2px;
background: url(navigatorBig.jpg) 0 -10px no-repeat;
display: block;
font-size: 1px;
}

/* mouseover state */
.navi a:hover
{
	background-position: 0 0;
}

/* active state (current page state) */
.navi a.active
{
	background-position: 0 -20px;
}


/*----- Headlines_scrollable -----*/
.Headlines_scrollable
{
  /* required settings */
  position:relative;
  overflow:hidden;
  width: 660px;
  height: 380px;
  /*background:url(/staticelement/img/default_img.jpg) center center no-repeat;*/
}

/*
   root element for scrollable items. Must be absolutely positioned
   and it should have a extremely large width to accomodate scrollable
   items.  it's enough that you set the width and height for the root
   element and not for this element.
*/
.Headlines_scrollable .items
{
  /* this cannot be too large */
  width: 20000em;
  position: absolute;
  clear: both;
}

.Headlines_scrollable .items div
{
  float:left;
  width:660px;
  background-color:Black;
  position: relative;
}

/* single scrollable item */
.Headlines_scrollable .s_item
{
  display:block;
  height: 380px;
  text-decoration: none;
  overflow: hidden;
}

.Headlines_scrollable .s_item .left_info
{
  width: auto;
  height: 80px;
  overflow: hidden;
	margin: 10px;
	z-index: 10;
  background-color: Black;	
}

.Headlines_scrollable .last
{
  border-right:none;
}


.Headlines_scrollable .s_item img 
{
  /*
  height:280px;
  width:457px;
  */
  height: 100%;
  width: 100%;
  max-width: 457px;
  position: relative;
  z-index: 5;
  left: 0px;
}

#Headlines .Headlines_scrollable .s_item h3
{
  color: white;
  line-height: 1.25em;
  margin-bottom: 10px;
}

.Headlines_scrollable .s_item p
{
  color: white;
  font-size: 1rem;
  font-size: 16px;
  margin-top: 2px;
  line-height: 1.6em;
  display: block;
  height: 113px;
  overflow: hidden;
}

/* active item */
.Headlines_scrollable .active
{
  border:2px solid #000;
  position:relative;
  cursor:default;
}

/* <<  Button  >>  */
.Headlines_scrollable
{
  float: left;
  top: 0px;
  left: 0px;
}

/* prev, next, prevPage and nextPage buttons */
#Headlines a.browse
{
  background: url(hori_large.jpg) no-repeat;
  display: block;
  width: 33px;
  height: 33px;
  float: left;
  cursor: pointer;
  font-size: 1px;
  position: absolute;
  z-index: 20;
  margin-top: 220px;
  *left: 0px;
}

/* right */
#Headlines a.right
{
  position:absolute;
  left:618px;
  *left:618px;
  background-position:0 -33px;
  clear:right;
  margin-left: 9px;
}

#Headlines .scroll_navi {
   position:relative;
   z-index:100;
   margin-top:-20px;
   float:right;
   left:4px;
   margin-right:0;
}

.headline_imgbox
{
  float: none !important;
  width: 100% !important;
  height: 280px !important;
  text-align: center;
}

/*----- NewsHeadlines_scrollable -----*/


.NewsHeadlines_scrollable
{
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 400px;
  background: url(/staticelement/img/ntd_logo.png) center center no-repeat;
}

/*
   root element for scrollable items. Must be absolutely positioned
   and it should have a extremely large width to accomodate scrollable
   items.  it's enough that you set the width and height for the root
   element and not for this element.
*/
.NewsHeadlines_scrollable .items {
    /* this cannot be too large */
    width:20000em;
    position:absolute;
    clear:both;
}

.NewsHeadlines_scrollable .items  div {
float: left;
width: 320px;
background-color:#f4f4f4;
}

/* single scrollable item */
.NewsHeadlines_scrollable .s_item
{
  display: block;
  position: relative;
  z-index: 1;
  width: 320px;
  height: 213px;
  text-decoration: none;
  overflow: hidden;
  margin: 0 auto;
}

.NewsHeadlines_scrollable .last
{
  border-right:none;
}

.NewsHeadlines_scrollable .s_item img 
{
  float:right;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index:5;
	left: 0px;
}

.NewsHeadlines_scrollable div.news_info
{
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  
  float: none;
  height: 187px;
  width: 100%;
  padding: 7.5px 15px;
}

.NewsHeadlines_scrollable div.news_info h3
{
  height: 50px;
}

.NewsHeadlines_scrollable div.news_info h3 a
{
  color:#174c9c;
  line-height:1.25em;
}

.NewsHeadlines_scrollable div.news_info .article_time
{
  display: block;
  height: 20px;
}

.NewsHeadlines_scrollable div.news_info .article_time a
{
  color: #737373;
  
  font-size: 12px;
  line-height: 1.35;
  vertical-align: top;
}

.NewsHeadlines_scrollable div.news_info p a
{
  font-size: 0.9em;
  line-height: 1.4;
  margin-top: 2px;
  display: block;
  height: 40px;
  overflow: hidden;
}

/* active item */
.NewsHeadlines_scrollable .active
{
  border:2px solid #000;
  position:relative;
  cursor:default;
}

/* <<  Button  >>  */
.NewsHeadlines_scrollable {
    float:left;
	top: 0px;
	left: 0px;
}

/* prev, next, prevPage and nextPage buttons */
#NewsHeadlines a.browse {
    background:url(hori_large.jpg) no-repeat;
    display:block;
    width:33px;
    height:33px;
    float:left;
    cursor:pointer;
    font-size:1px;
     position:absolute;
     z-index:20;
     margin-top: 120px;
      *left:0px;
}

/* right */
#NewsHeadlines a.right
{
  position:absolute;
  left: initial;
  *left: initial;
  background-position:0 -33px;
  clear:right;
  margin-left: 0px;
  right: 0px;
}

/* left */
#NewsHeadlines a.left{ margin-left:0;}

#NewsHeadlines .scroll_navi
{
  position: relative;
  z-index: 0;
  margin-top: -20px;
  float: right;
  left: 4px;
  margin-right: 0;
}

/*----- NewsProg_scrollable -----*/

.news_prog_scrollable
{
  position:relative;
  overflow:hidden;
  width: 320px;
  height:213px;
  padding-top:6px;
  clear:both;
  margin: 0 auto;
  background: url(/staticelement/img/default_img.jpg) 0px 6px no-repeat;
  background-size: contain;
}

/*
   root element for scrollable items. Must be absolutely positioned
   and it should have a extremely large width to accomodate scrollable
   items.  it's enough that you set the width and height for the root
   element and not for this element.
*/
.news_prog_scrollable .items {
    /* this cannot be too large */
    width:20000em;
    position:absolute;
    clear:both;
}

.news_prog_scrollable .items  div {
float: left;
width: 320px;
}

/* single scrollable item */
.news_prog_scrollable .s_item
{
  display:block;
  /*
  width:320px;
  height:213px;
  */
  text-decoration: none;
  overflow:hidden;
}

.news_prog_scrollable .last{
    border-right:none;
}

.news_prog_scrollable .s_item img 
{
  float:right;
  width:320px;
  height:213px;
  position:relative;
  /*top: 10px;*/
  left: 0px;
}
    .news_prog_scrollable div.news_info {
	 float:none;
	 height:86px;
	 width:290px;
	 padding:8px 15px 10px 15px;
	 background-color:#f4f4f4;
    }

    .news_prog_scrollable div.news_info h3 a
    {
    	color:#174c9c;
     line-height:1.45em;
     font-size: 0.9em;}
    .news_prog_scrollable div.news_info p a{
		font-size: 0.9em;
		margin-top: 2px;
		display: block;
		height: 80px;
		overflow: hidden;
    }
        		
/* active item */
.news_prog_scrollable .active {
    border:2px solid #000;
    position:relative;
    cursor:default;
}

#news_prog
{
  position:relative;
  height:260px;
  *height:245px;
  overflow:hidden;
}
	
#news_prog h3.news_category
{
  width: 100px;
  vertical-align: middle;
  color: white;
  font-size: 1.25rem;
  font-size: 20px;
}

/* prev, next, prevPage and nextPage buttons */

#news_prog a.browse
{
  background:url(hori_large.jpg) no-repeat;
  background-position:-5px -7px;
  display:block;
  width:22px;
  height:22px;
  cursor:pointer;
  font-size:1px;
  position:absolute;
  z-index:20;
  margin-top: 17px;
  *left:0px;
}

/* right */
#news_prog a.right
{
  position: absolute;
  left: initial;
  *left: initial;
  background-position:-7px -37px;
  clear:right;
  right: 14px;
}

/* left */
#news_prog a.left
{
  margin-left:0;
  right: 39px;
}

#news_prog .scroll_navi
{
  position: absolute;
  z-index: 1;
  margin-top: 17px;
  margin-left: 15px;
}

/*----- gp_vedio_list -----*/
#gp_vedio_list
{
	 padding-bottom:18px;
	 border-bottom:1px solid #ddd;
	}

#gp_vedio_list .scrollable
{
	/* required settings */
    position:relative;
    overflow:hidden;
    width: 660px;
    height:156px;
    margin-top:0px;
    clear:both;
    border:none;
	}
	
#gp_vedio_list .items div {
float: left;
width: 660px;
}

#gp_vedio_list .scrollable .s_item
{
	display: block;
	float: left;
	padding:0 0 5px 12px;
	width: 156px;
	height: 156px;
	border:none;
	text-decoration: none;
	overflow: hidden;
	}
	
#gp_vedio_list .scrollable .s_item:hover 
{
	 background-color:White;
	}
	
#gp_vedio_list .scrollable .s_item:first-child
{ 
 padding-left:0;
}

#gp_vedio_list .scrollable .s_item img {
	width: 156px;
	height: 106px;
}

#gp_vedio_list a.browse 
{
	position:relative;
	top:85px;
	z-index:100;
	background: url(hori_large.jpg) no-repeat;
	display: block;
	margin-top: -20px;
	width: 33px;
	height: 33px;
	float: left;
	cursor: pointer;
	font-size: 1px;
}
#gp_vedio_list  a.right {  left:618px; background-position:0 -33px; clear:right;}

#gp_vedio_list a.left { margin-left: -42px; }